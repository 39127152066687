@keyframes ldio-7d6v38vk6xc-1 {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-45deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes ldio-7d6v38vk6xc-2 {
  0% {
    transform: rotate(180deg);
  }
  50% {
    transform: rotate(225deg);
  }
  100% {
    transform: rotate(180deg);
  }
}
.ldio-7d6v38vk6xc > div:nth-child(2) {
  transform: translate(-15px, 0);
}
.ldio-7d6v38vk6xc > div:nth-child(2) div {
  position: absolute;
  top: 30px;
  left: 30px;
  width: 90px;
  height: 45px;
  border-radius: 90px 90px 0 0;
  background: #f8b26a;
  animation: ldio-7d6v38vk6xc-1 1s linear infinite;
  transform-origin: 45px 45px;
}
.ldio-7d6v38vk6xc > div:nth-child(2) div:nth-child(2) {
  animation: ldio-7d6v38vk6xc-2 1s linear infinite;
}
.ldio-7d6v38vk6xc > div:nth-child(2) div:nth-child(3) {
  transform: rotate(-90deg);
  animation: none;
}
@keyframes ldio-7d6v38vk6xc-3 {
  0% {
    transform: translate(142.5px, 0);
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    transform: translate(52.5px, 0);
    opacity: 1;
  }
}
.ldio-7d6v38vk6xc > div:nth-child(1) {
  display: block;
}
.ldio-7d6v38vk6xc > div:nth-child(1) div {
  position: absolute;
  top: 69px;
  left: -6px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #e15b64;
  animation: ldio-7d6v38vk6xc-3 1s linear infinite;
}
.ldio-7d6v38vk6xc > div:nth-child(1) div:nth-child(1) {
  animation-delay: -0.67s;
}
.ldio-7d6v38vk6xc > div:nth-child(1) div:nth-child(2) {
  animation-delay: -0.33s;
}
.ldio-7d6v38vk6xc > div:nth-child(1) div:nth-child(3) {
  animation-delay: 0s;
}
.loadingio-spinner-bean-eater-teu3rb1sc3p {
  width: 150px;
  height: 150px;
  display: inline-block;
  overflow: hidden;
  background: #ffffff;
  /* Addnew */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ldio-7d6v38vk6xc {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-7d6v38vk6xc div {
  box-sizing: content-box;
}
/* generated by https://loading.io/ */
