@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai:wght@300&display=swap');

.footer {
  font-family: 'IBM Plex Sans Thai', sans-serif;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: rgb(109, 106, 106);
  color: white;
  text-align: center;
  opacity: 0.9;
}