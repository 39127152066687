.box-text {
  text-align: center;
  font-size: 13px;
  font-weight: 900;
  background-color: aqua;
  margin-top: -8px;
  margin-right: 16px;
  border-radius: 8px;
}

.grid-select:hover {
  opacity: 0.6;
}
