@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai:wght@300&display=swap');

.Navbar h4 {
  font-family: 'IBM Plex Sans Thai', sans-serif;
  font-size: 18px;
}

.Navbar h4 a {
  text-decoration: none;
  color: #ffffff;
}
